//variables
@import './vars/typography.scss';

//pages
@import './pages/Main.scss';

body {
  margin: 0;
  font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',
    'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', sans-serif;
}

h1,
h2,
h3,
p {
  margin: 0;
}
