@mixin width-600 {
  @media (min-width: 600px) {
    width: 600px;
  }
}

.Main {
  background-color: #e5e5e5;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__main {
    @include width-600;
    background-color: #ffffff;
    color: #0c0d0d;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 75px;
    padding-bottom: 250px;
    gap: 5px;

    &__img {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 95px;
    }

    &__title {
      @include md-18-extrabold;
    }

    &__description {
      @include sm-14-medium;
      line-height: 30px;
      display: flex;
      flex-direction: column;
    }

    &__descriptionArea {
      background-color: #f6f6fa;
      color: #646466;
      border-radius: 8px;
      width: 100%;
      padding: 15px 20px;
      line-height: 1.5;
      resize: none;
    }

    &__amount {
      margin-top: 40px;
    }
  }

  &__info {
    @include width-600;
    @include sm-14-medium;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #585858;
    padding: 32px;
  }

  &__footer {
    @include width-600;
    @include sm-14-medium;
    display: flex;
    width: 100%;
    color: #585858;
    padding: 10px 32px;
    gap: 30px;
  }
}
