@mixin sm-12-medium {
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.25px;
    line-height: 22px;
  }
  
  @mixin sm-12-semibold {
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.25px;
    line-height: 22px;
  }
  
  @mixin sm-12-extrabold {
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 0.5px;
    line-height: 22px;
  }
  
  @mixin sm-14-medium {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 26px;
  }
  
  @mixin sm-14-semibold {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 26px;
  }
  
  @mixin sm-14-bold {
    font-size: 14px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 26px;
  }
  
  @mixin sm-14-extrabold {
    font-size: 14px;
    font-weight: 800;
    letter-spacing: 0.25px;
    line-height: 26px;
  }
  
  @mixin md-16-medium {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 28px;
  }
  
  @mixin md-16-semibold {
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 28px;
  }
  
  @mixin md-16-bold {
    font-size: 16px;
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 30px;
  }
  
  @mixin md-16-extrabold {
    font-size: 16px;
    font-weight: 800;
    letter-spacing: 0.25px;
    line-height: 30px;
  }
  
  @mixin md-18-medium {
    font-size: 18px;
    font-weight: 500;
    letter-spacing: 0.1px;
    line-height: 32px;
  }
  
  @mixin md-18-bold {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 32px;
  }
  
  @mixin md-18-extrabold {
    font-size: 18px;
    font-weight: 800;
    letter-spacing: 0.25px;
    line-height: 32px;
  }
  
  @mixin md-21-semibold {
    font-size: 21px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 34px;
  }
  
  @mixin md-21-bold {
    font-size: 21px;
    font-weight: 700;
    letter-spacing: 0.25px;
    line-height: 34px;
  }
  
  @mixin md-21-extrabold {
    font-size: 21px;
    font-weight: 800;
    letter-spacing: 0.25px;
    line-height: 36px;
  }
  
  @mixin lg-24-bold {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 38px;
  }
  
  @mixin lg-24-extrabold {
    font-size: 24px;
    font-weight: 800;
    letter-spacing: 0.25px;
    line-height: 38px;
  }
  
  @mixin lg-28-bold {
    font-size: 28px;
    font-weight: 700;
    letter-spacing: 0.1px;
    line-height: 46px;
  }
  
  @mixin lg-28-extrabold {
    font-size: 28px;
    font-weight: 800;
    letter-spacing: 0.25px;
    line-height: 46px;
  }
  
  @mixin lg-32-semibold {
    font-size: 32px;
    font-weight: 600;
    letter-spacing: 0.1px;
    line-height: 50px;
  }
  
  @mixin lg-32-extrabold {
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0.25px;
    line-height: 50px;
  }
  
  @mixin lg-40-extrabold {
    font-size: 40px;
    font-weight: 800;
    letter-spacing: 1px;
    line-height: 62px;
  }
  
  @mixin lg-70-extrabold {
    font-size: 70px;
    font-weight: 800;
    letter-spacing: 2px;
    line-height: 110px;
  }
  